<template>
  <div class="reservations-list">
    <PageTitleExtra
      title="預約列表"
      btn="新增"
      btn2="關閉時段"
      btn3="匯出"
      btn4="申報單匯出"
      btnFeature="admin.appointmentOrder.create"
      cyBtn1="new-reservation-btn"
      @btnClick="$router.push({ name: 'ReservationCreate' })"
      @btn2Click="togglePeriodDialog = true"
      @btn3Click="showExportOptions = true, exportType = 'report'"
      @btn4Click="showExportOptions = true, exportType = 'returnForm'"
    />
    <!-- <header class="view-title">預約列表</header> -->

    <div class="filters-wrapper">
      <div class="filter-row">
        <SubsidyMemberSearch
          class="filter-item"
          data-cy="member-select"
          placeholder="請輸入個案姓名"
          :model.sync="search.subsidyMember"
          @change="refresh(true)"
        />
        <el-date-picker
          v-model="search.dateRange"
          :disabled="!search.subsidyMember"
          class="filter-item"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']"
          @change="refresh(true)"
        />
      </div>
    </div>

    <FiltersContainer>
      <el-date-picker
        v-model="search.date"
        class="flex-grow-1 !w-full"
        type="date"
        placeholder="選擇預約日期"
        :style="checkAction('admin.resourceItem.page') ? 'grid-column: 1/3': ''"
        @change="refresh(true)"
      />

      <ServiceUnitSearch class="flex-grow-1" :model.sync="search.unit" @change="refresh" />
      <ResourceSearch v-if="checkAction('admin.resourceItem.page')" class="flex-grow-1" :model.sync="search.resource" @change="refresh" />
      <el-input
        v-model="search.code"
        class="flex-grow-1"
        clearable
        placeholder="搜尋訂單編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>

    <section class="flex justify-between items-end">
      <div class="flex-1">
        <p>當月預約訂單總數：{{ monthRecordCount }} / 每月預約上限：{{ contractReservationsCount }}</p>
        <ProgressBar :percentage="reservationsPercentage" />
      </div>
      <TableFilter
        style="margin-left: 15px"
        :tableName="tableName"
        :options="reservationListFilterOptions"
        @update="updateTableFilter"
      />
    </section>

    <section>
      <el-table v-if="!tableRefresh" v-loading="loading" :data="reservationsList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column prop="code" label="訂單編號" width="120" fixed align="center" />
        <el-table-column v-if="showColumn('orderType', tableFilter)" label="訂單類型" fixed width="100" align="center">
          <template slot-scope="scope">
            {{ getData(scope.row, 'AppointmentOrder.peopleCount', 1) > 1 ? '多人' : '單人' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="showColumn('subsidyName', tableFilter)"
          prop="SubsidyMember.userName"
          label="個案姓名"
          fixed
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ getData(scope.row, 'SubsidyMember.name', '-') }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('subsidyLevel', tableFilter)" prop="SubsidyMember.level" label="長照等級" width="120" align="center">
          <template slot-scope="scope">
            {{ getData(scope.row, 'SubsidyMember.level', '-') }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('userName', tableFilter)" prop="userName" label="預約人" align="center">
          <template slot-scope="scope">
            {{ scope.row.userName || (scope.row.numberCode >= 2 ? '未填寫' : '') }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('subsidyPartner', tableFilter)" prop="AppointmentReservationAipd.hasPartner" label="陪病者" width="120" align="center">
          <template slot-scope="scope">
            <Tag
              disable-transitions
              :type="getData(scope.row, 'AppointmentReservationAipd.hasPartner', false) ? 'action' : 'info'"
            >
              {{ getData(scope.row, 'AppointmentReservationAipd.hasPartner', null) === null ? '-' : (
                getData(scope.row, 'AppointmentReservationAipd.hasPartner') ? '是' : '否'
              ) }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('phone', tableFilter)" prop="userPhone" label="電話號碼" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.userPhone || (scope.row.numberCode >= 2 ? '未填寫' : '') }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('date', tableFilter)" prop="start" label="預約日期" width="115" align="center">
          <template slot-scope="scope">
            {{ dateTime(scope.row.start).date }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('time', tableFilter)" prop="start" label="預約時間" width="115" align="center">
          <template slot-scope="scope">
            {{ dateTime(scope.row.start).time }}
          </template>
        </el-table-column>

        <el-table-column v-if="showColumn('service', tableFilter)" prop="AppointmentService.name" label="指定地點" width="150" align="center" />
        <el-table-column v-if="showColumn('serviceUnit', tableFilter)" prop="AppointmentUnit.name" label="班次" width="150" align="center">
          <template slot-scope="scope">
            {{ showNotSpecify(scope.row) || '-' }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('resourceItem', tableFilter) && checkAction('admin.resourceItem.page')" prop="ResourceItem.name" label="服務設備" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.ResourceItem?.name || '-' }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('userAddress', tableFilter) && useMemberAddress" prop="AppointmentOrder.userAddress" label="個案地點" align="center" width="180">
          <template slot-scope="scope">
            {{ scope.row.AppointmentOrder.userAddress || '-' }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('origin', tableFilter)" prop="origin" label="預約來源" width="120" align="center">
          <template slot-scope="scope">
            {{ reservationOrigins(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('createdAt', tableFilter)" prop="createdAt" label="建立時間" width="120" align="center">
          <template slot-scope="scope">
            {{ createdAtFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>

        <!-- 訂金欄位 -->
        <el-table-column v-if="showColumn('orderPrice', tableFilter) && useDeposit" label="訂單總額" prop="status" width="105" align="center">
          <template slot-scope="scope">
            {{ orderTotalPrice(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('depositPrice', tableFilter) && useDeposit" label="應付訂金" prop="status" width="105" align="center">
          <template slot-scope="scope">
            {{ depositPrice(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('paymentType', tableFilter) && useDeposit" label="付款方式" prop="status" width="150" align="center">
          <template slot-scope="scope">
            {{ depositPaymentType(scope.row) }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="訂單狀態" prop="status" width="105" align="center">
          <template slot-scope="reservation">
            <Tag disable-transitions :type="orderStatus(getData(reservation.row, 'AppointmentOrder.status'), 'tag')">
              <!-- {{ statusTrans(getData(reservation.row, 'AppointmentOrder.status', '')) }} -->
              {{ orderStatus(getData(reservation.row, 'AppointmentOrder.status'), 'name') }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="useDeposit"
          fixed="right"
          label="付款狀態"
          prop="status"
          width="105"
          align="center"
        >
          <template slot-scope="reservation">
            <Tag
              v-if="getData(reservation.row, 'AppointmentOrder.AppointmentOrderDeposit')"
              :type="paymentStatusTagType(reservation.row)"
              disable-transitions
            >
              {{ paymentStatus(reservation.row) }}
            </Tag>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="60" align="center">
          <template slot-scope="reservation">
            <TableSettingIconButton @click="selectRow = reservation.row, showInfo=true" />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="reservationsCount"
        @pageChange="refresh"
      />
    </section>

    <!-- 預約資訊側邊彈窗 -->
    <ReservationOrderDetail
      :show="showInfo"
      :selecReservation="selectRow"
      @close="showInfo=false"
      @cancel="onCancel"
      @refresh="refresh"
      @updateReservation="onUpdateReservationTime"
    />

    <!-- 關閉時段 Dialog -->
    <TogglePeriodDialog v-if="togglePeriodDialog" title="編輯關閉時段" width="440px" @close="togglePeriodDialog = false" />

    <DeleteDialog
      v-if="deleteDialog"
      title="警示"
      :content="deleteContent"
      width="40%"
      btnString="取消預約"
      cancelBtnString="返回"
      @close="deleteDialog = false"
      @delete="cancelReservation()"
    />

    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      @close="showExportOptions = false"
      @export="showExportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="reservationsCount"
      @close="resetExport"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import TableFilter from '@/components/Button/TableFilter.vue'
import { reservationListFilterOptions } from '@/config/table'
import { mapGetters } from 'vuex'
import ProgressBar from '@/components/Progress/ProgressBar'
import ReservationOrderDetail from '@/components/Reservation/ReservationOrderDetail/ReservationOrderDetail.vue'
import { getTableFilterConfig, showColumn } from '@/utils/tableFilter'
import {
  GetReservation,
  GetReservationCount,
  UpdateReservationOrderStatus,
  GetReservationOrderCount,
  UpdateReservationAipdParam,
  SetAipdDepartureInfo,
  SetAipdGetIntoInfo,
  SetAipdGetOffInfo,
} from '@/api/reservation'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import ServiceUnitSearch from '@/components/Search/ServiceUnitSearch.vue'
import ResourceSearch from '@/components/Search/ResourceSearch.vue'
// import ServiceSearch from '@/components/Search/ServiceSearch.vue'
import { getData } from '@/utils/object'
import EmptyBlock from '@/components/EmptyBlock.vue'
import dayjs from 'dayjs'
// Utils
import { pageStartIndex } from '@/utils/table'
import {
  dateTimeFormat,
  statusTranslate,
  getAllDataFromApi,
} from '@/utils/helper'
import { reservationOrderOrigins } from '@/utils/reservation'
import { getMonth, getDay } from '@/utils/date'
import { get, map } from 'lodash'
import { depositPaymentTypes, depositStatusConfig } from '@/config/deposit'
import { orderStatusConfig } from '@/config/reservation'
import { ExportExcel } from '@/utils/excel'
import { checkUserFeature } from '@/store/modules/permission'
import SubsidyMemberSearch from '@/components/Search/SubsidyMemberSearch.vue'

export default {
  name: 'ReservationsList',
  components: {
    SubsidyMemberSearch,
    TableFilter,
    ProgressBar,
    DeleteDialog,
    TogglePeriodDialog,
    ServiceUnitSearch,
    ResourceSearch,
    // ServiceSearch,
    ReservationOrderDetail,
    EmptyBlock,
    PageTitleExtra,
    ExportDialog,
    ExportOptionsDialog,
  },
  data: () => ({
    // Export
    showExportOptions: false,
    exportType: '',
    exportting: false,
    exportError: false,
    exportData: null,
    exportPercentage: 0,
    showExportDialog: false,

    showOrderDrawer: false,

    tableRefresh: false,
    tableName: 'reservationList',
    // reservationListFilterOptions,
    tableFilter: [],
    deleteContent: '',
    showInfo: false,
    loading: false,
    deleteDialog: false,
    dialogType: '',
    togglePeriodDialog: false,
    selectRow: {
      AppointmentService: {},
      AppointmentUnit: {},
      AppointmentServiceAttaches: [],
    },
    search: {
      userName: '',
      service: '',
      resource: '',
      unit: '',
      date: '',
      code: '',
      subsidyMemberName: '',
      subsidyMember: null,
      dateRange: '',
    },
    options: {
      date: new Date(),
      name: '',
      service: '',
      serviceUnit: '',
    },
    reservationsList: [],
    reservationsCount: 0,
    monthRecordCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    colorList: [{
      color: '#1989fa',
      percentage: 80,
    },
    {
      color: '#f56c6c',
      percentage: 100,
    },
    ],
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
      'userFeatures',
      'userPlanFeature',
    ]),
    reservationListFilterOptions () {
      const blockList = []
      if (!this.useDeposit) blockList.push(...['orderPrice', 'depositPrice', 'paymentType'])
      if (!this.useMemberAddress) blockList.push('userAddress')
      if (!this.checkAction('admin.resourceItem.page')) blockList.push('resourceItem')
      return reservationListFilterOptions.filter(i => !blockList.includes(i.value))
      // return reservationListFilterOptions
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    contractReservationsCount () {
      return this.userPlanLimit.appointmentReservationLimitPerMonth
    },
    reservationsPercentage () {
      if (this.userPlanLimit.appointmentReservationLimitPerMonth === 0) {
        return 0
      }
      const count = Math.round(this.monthRecordCount * 100 / this.contractReservationsCount)
      return count > 100 ? 100 : count
    },
    useDeposit () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.appointmentDeposit.enable')
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
  },

  async mounted () {
    this.tableFilter = getTableFilterConfig(this.tableName, reservationListFilterOptions)
    this.showOrderDrawer = get(this.$route.query, 'openInviteRecord', 'false') === 'true'
    this.search.code = get(this.$route.query, 'code', '')
    await this.refresh()
  },
  methods: {
    showColumn,
    getData,
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    onCancel (data) {
      const orders = get(data, 'AppointmentOrder.AppointmentReservations')
      const orderCode = get(data, 'AppointmentOrder.code')
      if (!orders) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }
      if (!orders.length || orders.length === 1) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }

      const ordersCount = orders.length

      this.deleteContent = `訂單編號 ${orderCode} 有 ${ordersCount} 筆預約紀錄，將同時取消 ${ordersCount} 筆預約紀錄。`
      this.deleteDialog = true
    },
    onUpdateReservationTime (data) {
      const { type } = data
      const reqData = {}
      switch (type) {
      case 'departure':
        reqData.departureAt = new Date()
        break
      case 'getInto':
        reqData.getIntoAt = new Date()
        // reqData.address = get(this.selectRow, 'AppointmentOrder.userAddress')
        break
      case 'getOff':
        reqData.getOffAt = new Date()
        // reqData.address = get(this.selectRow, 'AppointmentService.address')
        break
      default:
        break
      }
      this.updateReservationAipdParam(type, reqData)
    },
    dateTime (dateTime) {
      return dateTimeFormat(dateTime)
    },
    statusTrans (status) {
      return statusTranslate(status)
    },

    orderStatus (status, attr) {
      return get(orderStatusConfig[status], attr)
    },
    tagType (val) {
      let type = 'info'
      if (val === 'complete') type = 'action'
      if (val === 'wait') type = 'info'
      if (val === 'cancel') type = 'danger'
      return type
    },
    async refresh (force = false) {
      this.loading = true
      if (force === true) this.tableOptions.page = 1
      await this.getReservation()
      await this.getReservationCount()
      await this.getMonthReservation()
      this.loading = false

      if (this.showOrderDrawer) {
        this.showInfo = this.showOrderDrawer
        this.selectRow = this.reservationsList[0]
      }
    },
    //= > 取得預約
    async getReservation (all) {
      try {
        let dateStart
        let dateEnd
        if (this.search.date !== '' && this.search.date) {
          const {
            start,
            end,
          } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
          dateStart = start
          dateEnd = end
        }

        if (this.search.dateRange) {
          [dateStart, dateEnd] = this.search.dateRange
        }
        this.search.code = this.search.code.trim()
        const res = await GetReservation({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          sort: (dateStart) ? 'start' : undefined,
          sortBy: (dateStart) ? 'ASC' : 'DESC',
          dateStart,
          dateEnd,
          userName: (this.search.userName === '') ? undefined : this.search.userName.trim(),
          AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
          AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
          ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
          code: (this.search.code === '') ? undefined : this.search.code.trim(),
          subsidyMemberName: (this.search.subsidyMember) ? this.search.subsidyMember.name : undefined,
        })
        this.reservationsList = res
        this.exportData = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async getAllReservation () {
      let dateStart
      let dateEnd
      if (this.search.date !== '' && this.search.date) {
        const {
          start,
          end,
        } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
        dateStart = start
        dateEnd = end
      }
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        sort: (dateStart) ? 'start' : undefined,
        sortBy: (dateStart) ? 'ASC' : 'DESC',
        dateStart,
        dateEnd,
        userName: (this.search.userName === '') ? undefined : this.search.userName.trim(),
        AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
        AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
        ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
        code: (this.search.code === '') ? undefined : this.search.code.trim(),
      }
      this.exportData = await getAllDataFromApi(
        this.reservationsCount,
        GetReservation,
        payload,
      )
    },
    //= > 取得本月預約數
    async getMonthReservation () {
      try {
        const now = dayjs(new Date())
        const year = now.format('YYYY')
        const month = now.format('MM')
        const {
          start,
          end,
        } = getMonth(year, month)

        const [res, err] = await GetReservationOrderCount({
          shopId: this.shop,
          dateStart: start,
          dateEnd: end,
        })
        if (err) return this.$message.error(err)
        this.monthRecordCount = res.count || 0
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 取得預約總數
    async getReservationCount () {
      try {
        let dateStart
        let dateEnd
        if (this.search.date !== '' && this.search.date) {
          const {
            start,
            end,
          } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
          dateStart = start
          dateEnd = end
        }
        if (this.search.dateRange) {
          [dateStart, dateEnd] = this.search.dateRange
        }
        this.reservationsCount = await GetReservationCount({
          shopId: this.shop,
          dateStart,
          dateEnd,
          userName: !this.search.userName ? undefined : this.search.userName.trim(),
          AppointmentUnitId: !this.search.unit ? undefined : this.search.unit.id,
          AppointmentServiceId: !this.search.service ? undefined : this.search.service.id,
          ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
          code: !this.search.code ? undefined : this.search.code.trim(),
          subsidyMemberName: (this.search.subsidyMember) ? this.search.subsidyMember.name : undefined,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 刪除(取消)預約
    async cancelReservation () {
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.selectRow, 'AppointmentOrder.id'),
        status: 'cancel',
      })
      if (err) return this.$message.error(err)
      this.deleteDialog = false
      this.showInfo = false
      this.refresh()
    },
    async updateReservationAipdParam (type, data) {
      switch (type) {
      case 'departure':
        await SetAipdDepartureInfo({
          shopId: this.shop,
          id: get(this.selectRow, 'id'),
          ...data,
        })
        break
      case 'getInto':
        await SetAipdGetIntoInfo({
          shopId: this.shop,
          id: get(this.selectRow, 'id'),
          ...data,
        })
        break
      case 'getOff':
        await SetAipdGetOffInfo({
          shopId: this.shop,
          id: get(this.selectRow, 'id'),
          ...data,
        })
        break
      }
      this.$message({
        message: '更新成功',
        type: 'success',
      })
      this.showInfo = false
      this.refresh()
    },

    reservationOrigins (row) {
      const notSpecify = row.fromNotSpecify
      const origin = getData(row, 'AppointmentOrder.origin', '')
      const originText = getData(row, 'AppointmentOrder.originText', '')
      return reservationOrderOrigins({ notSpecify, origin, originText })
    },
    showNotSpecify (row) {
      const notSpecify = row.fromNotSpecify
      if (row.AppointmentUnit) return notSpecify ? `${row.AppointmentUnit?.name} (不指定)` : row.AppointmentUnit?.name
      else return '-'
    },
    updateTableFilter () {
      this.tableRefresh = true
      this.tableFilter = getTableFilterConfig(this.tableName)
      setTimeout(() => {
        this.tableRefresh = false
      }, 0)
    },

    orderTotalPrice (row) {
      const orderPrice = get(row, 'AppointmentOrder.totalPrice')
      if (!orderPrice) return '-'
      return `$ ${orderPrice}`
    },

    depositPaymentType (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      const type = depositData.paidType
      if (type === 'offline') {
        const comment = depositData.paidTypeComment
        return get(depositPaymentTypes[comment], 'name')
      }
      return get(depositPaymentTypes[type], 'name')
    },

    depositPrice (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      if (depositData.depositPrice === 0) return '-'
      return `$ ${depositData.depositPrice}`
    },
    paymentStatus (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      const status = depositData.status
      return get(depositStatusConfig[status], 'name')
    },
    paymentStatusTagType (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return ''
      const status = depositData.status
      return get(depositStatusConfig[status], 'tag')
    },

    // ANCHOR Export Methods
    resetExport () {
      this.showExportDialog = false
      this.exportting = false
      this.exportError = false
      this.exportData = null
      this.exportPercentage = 0
    },

    async prepareExport () {
      if (!this.reservationsCount) {
        this.$message.warning('沒有資料可以匯出')
        return
      }
      this.exportError = false
      this.showExportDialog = true
      this.exportting = true
      await this.getReservationCount()
      await this.getAllReservation(true)
      switch (this.exportType) {
      case 'report':
        await this.formatExportData()
        break
      case 'returnForm':
        await this.formatReturnFormData()
        break
      default:
        break
      }
      this.exportting = false
    },
    async formatExportData () {
      const data = []
      let count = 0
      let haveDeposit = false
      // const haveDeposit = false

      this.exportData.forEach((item) => {
        const order = get(item, 'AppointmentOrder', {})
        const deposit = get(item, 'AppointmentOrder.AppointmentOrderDeposit')
        const attachServices = get(item, 'AppointmentServiceAttaches', [])
        const subsidyMemberName = get(item, 'SubsidyMember.name', '-')
        const month = dayjs(item.start).month()
        const recordCount = this.exportData.filter(i => {
          const iSubsidyMemberName = get(i, 'SubsidyMember.name', '-')
          return iSubsidyMemberName !== '-' &&
            iSubsidyMemberName === subsidyMemberName &&
            dayjs(i.start).month() === month
        }).length

        if (deposit) haveDeposit = true

        const row = {
          訂單編號: get(order, 'code', '-'),
          預約單號: item.numberCode,
          訂單狀態: this.statusTrans(item.status) || '-',
          付款狀態: this.paymentStatus(item),
          訂單類型: get(order, 'peopleCount', 1) > 1 ? '多人' : '單人',
          預約人: item.userName || '未填寫',
          個案姓名: get(item, 'SubsidyMember.name', '-'),
          長照等級: get(item, 'SubsidyMember.level', '-'),
          本月已預約次數: recordCount,
          陪病者: get(item, 'AppointmentReservationAipd.hasPartner', null) === null ? '-' : (get(item, 'AppointmentReservationAipd.hasPartner') ? '是' : '否'),
          電話號碼: item.userPhone || '未填寫',
          預約日期: this.dateTime(item.start).date,
          預約時間: this.dateTime(item.start).time,
          服務車次: this.showNotSpecify(item) || '-',
          預約收件地點: get(item, 'AppointmentOrder.userAddress') || '-',
          服務項目: get(item, 'AppointmentService.name', '-'),
          附加服務: map(attachServices, 'name').join('、') || '-',
          會員備註: item.userComment || '-',
          備註: get(order, 'adminComment') || '-',
          預約來源: this.reservationOrigins(item),
          建立時間: this.createdAtFormat(item.createdAt),
          訂單總額: item.totalPrice || '-',
          應付訂金: get(deposit, 'depositPrice', '-'),
          付款方式: this.depositPaymentType(item),
          交易序號: get(deposit, 'code', '-'),
        }

        // if (!deposit) {
        //   delete row['付款狀態']
        //   delete row['訂單總額']
        //   delete row['應付訂金']
        //   delete row['付款方式']
        //   delete row['交易序號']
        // }

        data.push(row)
        count++
        this.exportPercentage = (count / this.reservationsCount) * 100
      })
      if (!haveDeposit) {
        data.forEach(row => {
          delete row['付款狀態']
          delete row['訂單總額']
          delete row['應付訂金']
          delete row['付款方式']
          delete row['交易序號']
        })
      }
      ExportExcel(data, '預約紀錄', '預約紀錄')
    },
    async formatReturnFormData () {
      const data = []
      let count = 0
      let haveDeposit = false
      // const haveDeposit = false

      this.exportData
        .filter(item => item.status !== 'cancel')
        .forEach((item) => {
          const order = get(item, 'AppointmentOrder', {})
          const deposit = get(item, 'AppointmentOrder.AppointmentOrderDeposit')

          if (deposit) haveDeposit = true

          console.log(this.dateTime(get(item, 'AppointmentReservationAipd.getIntoAt', '')).dayjsObject)

          const getIntoAt = get(item, 'AppointmentReservationAipd.getIntoAt', '')
          const getOffAt = get(item, 'AppointmentReservationAipd.getOffAt', '')
          const userComment = get(order, 'userComment') || '無'
          const adminComment = get(order, 'adminComment') || '無'

          const row = {
            身分證字號: get(item, 'SubsidyMember.identityCard', '-'),
            服務日期: this.dateTime(item.start).taiwanDateTime,
            服務項目代碼: '',
            服務類別: '',
            數量: '',
            單價: '',
            服務人員身分證: this.showNotSpecify(item) || '-', // '填入司機姓名',
            '起始時段-小時': getIntoAt ? this.dateTime(getIntoAt).dayjsObject.format('HH') : '-',
            '起始時段-分鐘': getIntoAt ? this.dateTime(getIntoAt).dayjsObject.format('mm') : '-',
            '結束時段-小時': getOffAt ? this.dateTime(getOffAt).dayjsObject.format('HH') : '-',
            '結束時段-分鐘': getOffAt ? this.dateTime(getOffAt).dayjsObject.format('mm') : '-',
            備註: `會員備註: ${userComment}, 額外筆記: ${adminComment}`,
            服務人員身分證2: '',
            服務人員身分證3: '',
            服務人員身分證4: '',
            服務人員身分證5: '',
            不申報AA09填1: '',
            訪視未遇填1: '',
            'C碼必填-復能目標達成情形': '',
            'C碼必填-復能目標': '',
            'C碼必填-指導對象': '',
            'C碼必填-服務內容': '',
            'C碼必填-指導建議摘要': '',
            'OT01必填-餐別': '',
            'BD03、DA01使用-出發地': get(order, 'userAddress', ''),
            'BD03、DA01使用-目的地': get(item, 'AppointmentService.name', ''),
            'BD03、DA01使用-出發地(經度)': get(item, 'AppointmentReservationAipd.getIntoGeo.lng', ''),
            'BD03、DA01使用-出發地(緯度)': get(item, 'AppointmentReservationAipd.getIntoGeo.lat', ''),
            'BD03、DA01使用-目的地(經度)': get(item, 'AppointmentReservationAipd.getIntoGeo.lng', ''),
            'BD03、DA01使用-目的地(緯度)': get(item, 'AppointmentReservationAipd.getOffGeo.lat', ''),
            'BD03、DA01使用-里程數(公里)': get(item, 'AppointmentReservationAipd.distance', ''),
            'BD03、DA01使用-車號': this.showNotSpecify(item) || '-',
            'BD03必填-服務使用類型': '',
          }
          data.push(row)
          count++
          this.exportPercentage = (count / this.reservationsCount) * 100
        })
      if (!haveDeposit) {
        data.forEach(row => {
          delete row['付款狀態']
          delete row['訂單總額']
          delete row['應付訂金']
          delete row['付款方式']
          delete row['交易序號']
        })
      }
      const today = dayjs()
      ExportExcel(data, '照管平台上傳格式', `照管平台上傳格式${today.year() - 1911}${today.format('MMDD')}`, 'xls')
    },

    createdAtFormat (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
  },
}
</script>

<style scoped lang="postcss">
.reservations-list {
  height: 100%;
}
::v-deep .filter-item .el-input {
  max-width: 340px !important;
}
::v-deep .filter-item.el-date-editor {
  max-width: 340px !important;
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}
</style>
